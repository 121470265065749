<template>
  <div>
    <loading 
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>   
    <b-row v-if="!loading">                   
      <b-col 
        lg="10" offset-lg="1" >        
        <CCard :accent-color="colorHeader">
          <CCardHeader style="text-align: left;">
            <strong>{{title}}</strong>
          </CCardHeader>
          <CCardBody> 
            <b-row v-if="error">
              <b-col cols="12">
                <div 
                  class="alert alert-danger" 
                  role="alert" 
                  v-for="error_message in errors" v-bind:key="error_message">
                    {{error_message}}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <!-- Picture -->
              <b-col lg="3" md="4" style="text-align: center;">                  
                <b-form-group>
                  <!-- <img 
                    :src="pet.url ? pet.url : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'"
                    alt="" 
                    class="img-thumbnail rounded mx-auto d-block">                 -->
                  <img 
                    :src="pet.url ? pet.url : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'"
                    alt="" 
                    class="profile-user-image">                
                </b-form-group>                                            
                <b-form-file
                  v-model="pet.picture"
                  placeholder="Cambiar foto de mascota"
                  drop-placeholder="Coloca tu foto aquí..."
                  accept=".jpg, .png, .jpeg"
                  browse-text="Buscar"
                  @input="updateURL">
                </b-form-file>    
              </b-col>      
                
              <b-col lg="8" md="8">                                 
                <!-- Name -->
                <b-row class="my-1">
                    <b-col 
                      lg="4" sm="3"
                      style="text-align:left">
                        <label for="pet.name"><strong>Nombre </strong><small class="alert-text">*</small> </label>
                    </b-col>
                    <b-col lg="8" sm="9">
                        <b-form-group>                                                           
                        <b-form-input
                            type="text"
                            v-model.trim="$v.pet.name.$model"
                                :class="{'form-control is-invalid': $v.pet.name.$error,
                                        'form-control is-valid': !$v.pet.name.$invalid}">
                        </b-form-input>
                         
                        <b-form-invalid-feedback v-if="!$v.pet.name.required">
                            El nombre de tu mascota es obligatorio
                        </b-form-invalid-feedback>                    
                        <b-form-invalid-feedback v-if="!$v.pet.name.minLength">
                            El nombre debe tener como mínimo {{$v.pet.name.$params.minLength.min}} letras
                        </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <!-- Breed -->
                <b-row class="my-1">
                    <b-col 
                      lg="4" sm="3" 
                      style="text-align:left"
                    >
                    <label for="account_type"><strong> Raza <small class="alert-text">*</small></strong></label>
                    </b-col>
                    <b-col lg="8" sm="9">
                      <small>Escribe la raza para filtrar</small>
                      <b-form-group>
                        <v-select 
                          v-model="pet.breed"
                          :options="breeds" 
                          placeholder="Selecciona una raza">
                        </v-select>  
                      </b-form-group>
                        
                    </b-col>
                </b-row>
                <!-- DOF -->
                <b-row class="my-1">
                  <b-col 
                    lg="4" sm="3"
                    style="text-align:left">
                      <label for="account_type"><strong> Fecha de nacimiento </strong></label>
                  </b-col>
                  <b-col lg="8" sm="9">
                    <b-form-group>
                      <CInput                                                                                                    
                        type="date"
                        placeholder="dd/mm/yyyy"   
                        v-model="pet.dof"/>                                                      
                    </b-form-group>                                                                
                  </b-col>
                </b-row>
                <!-- City -->
                <b-row class="my-1">
                  <b-col 
                    lg="4" sm="3"
                    style="text-align:left">
                      <label for="account_type"><strong> Ciudad *</strong></label>
                  </b-col>
                  <b-col lg="8" sm="9">                    
                    <small>Dónde reside tu mascota?</small>
                    <b-form-group>                      
                      <vue-google-autocomplete
                        v-model.trim="$v.city.$model"
                        id="city"
                        ref="city"
                        class="form-control"
                        :class="{'form-control is-invalid': $v.city.$error,
                                                    'form-control is-valid': !$v.city.$invalid}"
                        placeholder="Selecciona tu ciudad con Google Maps"
                        v-on:placechanged="getAddressData"
                        v-on:no-results-found="cityDataNotFound"
                        v-on:inputChange="cityDataChanged"
                        v-on:blur="cityBlur"
                        autocomplete="off"
                        types="(cities)"
                        :country="['co', 'pe']"
                      ></vue-google-autocomplete>

                      <b-form-invalid-feedback
                        v-if="!$v.city.required">
                          La ciudad es necesaria, busca y selecciona una opción
                      </b-form-invalid-feedback>  
                       <b-form-invalid-feedback 
                        v-if="!$v.city.minLength">
                          La ciudad es necesario, busca y selecciona una opción
                        </b-form-invalid-feedback>                                                   
                    </b-form-group>                                                                
                  </b-col>
                </b-row>                
                <!-- Observations -->
                <b-row class="my-1">
                    <b-col 
                      lg="4" sm="3"
                      style="text-align:left">
                        <label for="pet.obs"><strong>Observaciones </strong></label>
                    </b-col>
                    <b-col lg="8" sm="9">
                      <small>Registra los cuidados y/o condiciones a tener en cuenta.</small>
                      <b-form-group>                                                           
                        <b-form-textarea                            
                            v-model="pet.obs"  
                            rows="4"
                            max-rows="6"
                                        >
                        </b-form-textarea>                                                
                      </b-form-group>
                    </b-col>
                </b-row>

                <hr>
                <!-- Vet name-->
                <b-row class="my-1">
                  <b-col lg="12" class="text-center">
                    <strong> Datos médicos (opcional)</strong>                  
                  </b-col>
                  <b-col 
                    lg="4" sm="3"
                    style="text-align:left">
                      <label for="pet.name"><strong>Veterinaria</strong></label>
                  </b-col>
                    <b-col lg="8" sm="9">
                      <b-form-group>                                                           
                        <b-form-input
                          type="text"
                          v-model="pet.vet">
                        </b-form-input>                                                
                      </b-form-group>
                    </b-col>
                </b-row>

                <!-- Vet address-->
                <b-row class="my-1">                 
                  <b-col 
                    lg="4" sm="3"
                    style="text-align:left">
                      <label for="pet.name"><strong>Dirección de Veterinaria</strong></label>
                  </b-col>
                    <b-col lg="8" sm="9">
                      <b-form-group>                                                           
                        <b-form-input
                          type="text"
                          v-model="pet.vetAddress">
                        </b-form-input>                                                
                      </b-form-group>
                    </b-col>
                </b-row>
                <!-- Vet phone-->
                <b-row class="my-1">                 
                  <b-col 
                    lg="4" sm="3"
                    style="text-align:left">
                      <label for="pet.name"><strong>Teléfono de Veterinaria</strong></label>
                  </b-col>
                    <b-col lg="8" sm="9">
                      <b-form-group>                                                           
                        <b-form-input
                          type="text"
                          v-model="pet.vetPhone">
                        </b-form-input>                                                
                      </b-form-group>
                    </b-col>
                </b-row>
                
              </b-col>
            </b-row>                   
          </CCardBody>
          <CCardFooter>                     
            <b-row>
              <b-col
                  lg="3" offset-lg="3" md="4" offset-md="2" sm="6"
                  style="text-align:center; margin-bottom:1rem;">
                <CButton 
                  class="s-bg s-bg-text mr-1"
                  style="width:100%;"
                  @click="submit" 
                  >
                    {{buttonTitle}}      
                </CButton>
              </b-col>
              <b-col
                lg="3" md="4" sm="6"
                style="text-align:center; margin-bottom:1rem;">
                  <b-button 
                    class="mr-1"
                    style="width:100%;"
                    @click="cancel" 
                    >
                      Cancelar      
                  </b-button>                                                          
              </b-col>
            </b-row>                                    
          </CCardFooter>
        </CCard>
      </b-col>      
    </b-row>
  </div>
</template>

<script>
import { required, minLength, maxLength, email, numeric, requiredUnless } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import firebase from 'firebase';
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "form_pet",
  components: {    
    Loading,
    VueGoogleAutocomplete,
  },
  props:['petId'],
  data () {
    return {
      loading: true,
      userProfile: null,
      userId: null,
      pet: {
        id:null,
        name: '',
        breed: null,
        dof: null, 
        url: null,    
        picture: null,
        tag: null,  
        obs: null,
        vet: null,
        vetAddress: null,
        vetPhone: null,            
      },
      breeds: [],
      isToCreate: false,
      isToEdit: false,
      error: false,
      errors: [],
      cities: [],
      city: null,
      cityMatched: null,
    }
  },
  validations: {        
    pet: {      
      name: {
        required,
        minLength: minLength(2)
      },          
    },
    city: {
      required,
      minLength: minLength(4)
    },
  },
  computed: {
    colorHeader () {
      if(this.isToEdit){
        return 'warning'
      } 
      if(this.isToCreate) {
        return 'success'
      }
    },
    title () {
      if(this.isToEdit){
        return 'Editar mascota'
      } 
      if(this.isToCreate) {
        return 'Crear nueva mascota'
      } 
    },
    buttonTitle () {
      if(this.isToEdit){
        return 'Guardar'
      } 
      if(this.isToCreate) {
        return 'Crear'
      }  
    },
    citiesFixed: {
      get(){
        let items
        this.cities.map((city) => {
          let item= {
            value: city.id,
            label: city.nombre
          }
          items.push(item)
        })
        return items;
      }, 
      set(newValue) {
        return newValue;
      }
    }
  },
  created () {   
    this.userProfile= JSON.parse(this.$store.getters.userProfile);
    this.cities = this.$store.getters.cities;    
    this.userId = this.$store.getters.userId;
    this.fetchBreeds();       
    // console.log('petId: ', this.petId)
    // console.log(this.userProfile.pets) 
         
    if(this.petId != 0){           
      if (this.userProfile){   
        let pets = this.userProfile.pets           
        let petsFiltered= pets.filter(pet => pet.id == this.petId)
        this.pet = petsFiltered[0];                
        this.city = this.pet.city
        this.isToEdit = true   
        this.isToCreate = false        
        this.loading = false
      };
    } else {          
      this.city = this.userProfile.ciudad 
            ? this.userProfile.ciudad: '';   
      this.isToEdit = false 
      this.isToCreate = true     
    }

    let cityFound = this.cities.find(city => city.nombre == this.city)  
    if(cityFound == undefined){
      this.cityMatched = {
        value: 0,
        label: this.city
      }  
    } else {
      this.cityMatched = {
        value: cityFound.id,
        label: cityFound.nombre
      }  
      this.loading = false
    }                 
  },
  methods:{
    scrollToTop() {
      window.scrollTo(0,0);
    },
    updateURL(){                        
      let url = URL.createObjectURL(this.pet.picture)
      this.pet['url'] = url
      url = null
    },   
    fetchBreeds () {
        this.$store.dispatch('fetchBreeds').then((breeds) => {                     
        this.breeds = breeds
      }).catch((error) => {            
        console.log(error)
      }).finally()
    },  
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {                    
        this.error = true
        this.errors = ['No has completado correctamente tus datos']
        this.scrollToTop()                 
      } else {        
        this.setPayload()
      }
    },
    async setPayload () {            
      let breed1 = null
      if(this.pet.breed){
        breed1 = {
          id: this.pet.breed.value,
          nombre: this.pet.breed.label, 
        }
      }else{
        breed1 = null
      }      
      if(this.pet.picture){    
        this.loading = true    
        this.pet.url = await this.postPetImage()        
      }      
      let data = {
        id: this.pet.id,
        name: this.pet.name,
        breed: breed1,
        dof: this.pet.dof=='' ? null : this.pet.dof,
        url: this.pet.url ? this.pet.url : null,
        celular: this.userProfile.celular,
        direccion: this.userProfile.direccion,
        city: this.city,
        cityMatched: this.cityMatched,
        obs: this.pet.obs,
        vet: this.pet.vet ? this.pet.vet : '',
        vetAddress: this.pet.vetAddress ? this.pet.vetAddress : '',
        vetPhone: this.pet.vetPhone ? this.pet.vetPhone : '',
      };
      this.loading = true
      
      if (this.isToCreate) {        
        data['cliente'] = {
          id: this.userProfile.id,
          nombre: this.userProfile.nombre
        }       
        // console.log(data) 
        console.log('createPet')
        this.postCreatePet(data)        
      } else if (this.isToEdit) {  
        // console.log(data)    
        console.log('editPet')   
        this.putEditPet(data)
      }

    },
    putEditPet(petData) {
      let editPetFunc = this.$firebase.functions().httpsCallable('editPet');
      editPetFunc(petData).then((result) => {
        // console.log(result.data.data)        
        this.$router.replace('/pets/index?edited=true')        
      }).catch((error) => {
        console.log(error)
        this.loading = false
      })

    },
    postCreatePet(petData) {      
      let createPetFunc = this.$firebase.functions().httpsCallable('createPet')
      createPetFunc(petData).then((result) => {
        // console.log(result.data.data)        
        this.cleanPetData()        
        this.$router.replace('/pets/index?created=true')
      }).catch((error) => {
        console.log(error)
        this.loading = false
      })
    },
    cancel(){
      this.$router.replace('/pets/index')
    },   
    cleanPetData(){
      this.pet = {
        id:null,
        name: null,
        breed: null,
        dof: null, 
        url: null,    
        picture: null,
        tag: null,          
      }
    },
    postPetImage(){
      let userId = this.$store.getters.userId
      const fileName = this.pet.picture.name
      const file = this.pet.picture
      const extension = fileName.slice(fileName.lastIndexOf('.'))
      return new Promise ((resolve, reject) => {
        let fileTask = firebase.storage().ref('users/profile/' + userId + '/'  + fileName).put(file)
        fileTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        } , function(error) {
          reject(error)
        }, async function() {
          let downloadURL = await fileTask.snapshot.ref.getDownloadURL()
            // Upload completed successfully, now we can get the download URL
          resolve(downloadURL)
        });
      })
    },
    getAddressData(addressData, placeResultData, id) {
      console.log(addressData);
      // console.log(placeResultData)
      let country = addressData.country;
      // let locality = addressData.locality
      let fomatted_address = placeResultData.formatted_address;
      this.currencySelected =
        country == "Perú" || country == "Peru" ? "PEN" : "COP";
      // this.city = locality != null ? locality :  fomatted_address
      this.city = fomatted_address;

      let cityFound = this.cities.find(city => city.nombre == this.city)
        
      if(cityFound == undefined){
        this.cityMatched = {
          value: 0,
          label: this.city
        }  
      } else {
        this.cityMatched = {
          value: cityFound.id,
          label: cityFound.nombre
        }
      }
      // console.log('cityMatched: ', this.cityMatched)
      // this.$v.$touch()
    },
    cityDataNotFound(address) {
      this.$v.city = null;
      // this.$v.$touch();
    },
    cityDataChanged(inputVal) {
      if (inputVal.newVal != this.city) {
        this.city = null;
      }
      this.$v.city.$touch();
      // this.$v.$touch();
    },
    cityBlur() {
      this.$v.city.$touch();
    },
  }
}
</script>

<style scoped>
.profile-user-image{
    border-radius: 50%;
    border: 2px solid white;
    width: 13rem;
    height: 13rem;  
    overflow: hidden;
    vertical-align:middle;
    display: inline-block;
    background-color: white;
  }
</style>