<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    >
    </loading>
    <div>
      <form @submit.prevent>
        <div animated fadeIn>
          <b-row>
            <!-- Pets -->
            <b-col lg="10" offset-lg="1">
              <CCard>
                <CCardHeader>
                  <strong>Mis mascotas</strong>
                </CCardHeader>
                <CCardBody>
                  <div v-for="(pet, index) in $v.pets.$each.$iter">
                    <b-card border-variant="dark">
                      <b-row>
                        <b-col lg="12">
                          <div v-if="index != 0">
                            <span
                              class="float-right"
                              style="cursor:pointer"
                              @click="removePet(index)"
                            >
                              Quitar
                              <CIcon
                                name="cilTrash"
                                size="md"
                                alt="Logo"
                                style="color:red;"
                              />
                            </span>
                          </div>
                        </b-col>

                        <!-- Pic -->
                        <b-col lg="3" md="4">
                          <label for="picture">
                            <strong>Foto</strong>
                          </label>

                          <b-form-file
                            v-model="picture"
                            placeholder="Selecciona una foto"
                            drop-placeholder="Coloca tu foto aquí..."
                            accept=".jpg, .png, .jpeg"
                            browse-text="Buscar"
                            @input="updateURL(index, pet)"
                          >
                          </b-form-file>

                          <b-form-group>
                            <!-- <img 
                                :src="pet.url.$model ? pet.url.$model : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'"
                                alt="" 
                                class="profile-user-image"> -->
                            <img
                              :src="
                                pet.url.$model
                                  ? pet.url.$model
                                  : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                              "
                              alt=""
                              class="img-thumbnail rounded mx-auto d-block"
                            />
                          </b-form-group>
                        </b-col>

                        <!-- Pet Info -->
                        <b-col lg="9" md="8">
                          <b-row class="my-1">
                            <b-col sm="3">
                              <label for="pet.name"
                                ><strong>Nombre </strong
                                ><small class="alert-text">*</small>
                              </label>
                            </b-col>
                            <b-col sm="9">
                              <b-form-group>
                                <b-form-input
                                  type="text"
                                  v-model.trim="pet.name.$model"
                                  :class="{
                                    'form-control is-invalid': pet.name.$error,
                                    'form-control is-valid': !pet.name.$invalid
                                  }"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback
                                  v-if="pet.name.required"
                                >
                                  El nombre de tu mascota es obligatorio
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                  v-if="!pet.name.minLength"
                                >
                                  El nombre debe tener como mínimo
                                  {{ pet.name.$params.minLength.min }} letras
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <b-row class="my-1">
                            <b-col sm="3">
                              <label for="account_type"
                                ><strong>
                                  Raza
                                  <small class="alert-text">*</small></strong
                                ></label
                              >
                            </b-col>
                            <b-col sm="9">
                              <v-select
                                v-model.trim="pet.breed.$model"
                                :options="breeds"
                                placeholder="Selecciona una raza"
                              />
                            </b-col>
                          </b-row>

                          <br />

                          <b-row class="my-1">
                            <b-col sm="3">
                              <label for="account_type"
                                ><strong> Fecha de nacimiento </strong></label
                              >
                            </b-col>
                            <b-col sm="9">
                              <b-form-group>
                                <CInput
                                  type="date"
                                  placeholder="dd/mm/yyyy"
                                  v-model.trim="pet.dof.$model"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                </CCardBody>
                <CCardFooter>
                  <b-row>
                    <b-col
                      lg="4"
                      offset-lg="2"
                      md="4"
                      offset-md="2"
                      sm="6"
                      style="text-align:center; margin-bottom:1rem;"
                    >
                      <b-button
                        size="sm"
                        variant="success"
                        @click="submit"
                        style="width:100%;"
                      >
                        <i class="fa fa-dot-circle-o"></i> Guardar
                      </b-button>
                    </b-col>
                    <b-col lg="4" md="4" sm="6" style="text-align:center;">
                      <b-button
                        size="sm"
                        variant="primary"
                        @click="addNewPet"
                        style="width:100%;"
                      >
                        <i class="fa fa-dot-circle-o"></i> Nueva mascota
                      </b-button>
                    </b-col>
                  </b-row>
                </CCardFooter>
              </CCard>
            </b-col>
          </b-row>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
  requiredUnless
} from "vuelidate/lib/validators";
import firebase from "firebase";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { setupCalendar, DatePicker } from "v-calendar";
import moment from "moment";
import Loading from "vue-loading-overlay";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const today = new Date();

setupCalendar({
  firstDayOfWeek: 2 // Monday
});

export default {
  name: "Create",
  components: {
    Loading,
    VueGoogleAutocomplete,
    "v-date-picker": DatePicker,
    vSelect
  },
  data() {
    return {
      loading: true,
      error: null,
      errors: [],
      picture: null,
      url: null,
      pets: [
        {
          id: null,
          name: null,
          breed: null,
          dof: null,
          url: null,
          picture: null
        }
      ],
      breeds: []
    };
  },
  created() {
    let userId = this.$store.getters.userId;
    let userProfile = JSON.parse(this.$store.getters.userProfile);
    if (userProfile) {
      console.log(userProfile);
      this.fetchBreeds();
      this.pets = userProfile.pets;
      this.loading = false;
    }
  },
  validations: {
    pets: {
      $each: {
        id: {},
        name: {
          required,
          minLength: minLength(2)
        },
        breed: {
          required
        },
        dof: {},
        url: {
          required
        },
        picture: {
          required
        }
      }
    }
  },
  methods: {
    submit() {
      console.log("submit");
    },
    addNewPet: function() {
      this.pets.push({
        name: null,
        degree: null,
        start_date: null,
        end_date: null
      });
    },
    removePet: function(indexElement) {
      if (indexElement != 0) {
        let removedElement = this.pets[indexElement];
        if ("id" in removedElement) {
          this.deletePet(removedElement["id"]);
        }

        this.pets.splice(indexElement, 1);
      }
    },
    deletePet(petId) {
      // let deletePet = this.$firebase.functions().httpsCallable('deleteUserEducation');
      // let petParams = {
      //     'user_id': this.userId,
      //     'user_education_id': userEducationId
      // }
      // deletePet(petParams).then((result) => {
      //     console.log(JSON.stringify(result));
      // }).catch((error) => {
      //     console.log(error);
      // });
    },
    updateURL(index, pet) {
      this.url = URL.createObjectURL(this.picture);
      this.pets[index]["url"] = this.url;
      this.url = null;
    },
    fetchBreeds() {
      this.$store
        .dispatch("fetchBreeds")
        .then(breeds => {
          this.breeds = breeds;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.profile-user-image {
  border-radius: 50%;
  border: 2px solid white;
  width: 7rem;
  height: 7rem;
  /* width: 10vw;
    height: 10vw; */
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  background-color: white;
}
</style>
