<template>
  <div>            
    <form-pet
      :petData="petData"/>
  </div>
</template>

<script>
  import FormPet from './Form'
  export default {      
    name: 'editPet',
    props: ['petData'],
    components: {FormPet},
    data () {
      return {
        
      }
    },
    created () {
      console.log('EditPet: ', this.petData)
    }
  }
</script>

<style scoped>

</style>