<template>
  <b-container fluid>
    <!-- Actions with pets -->
    <b-row>
      <b-col md="12">        
        <b-alert
          v-model="flagAlertCreation"
          variant="success"
          dismissible
        >¡Tu mascota ha sido registrada exitosamente!</b-alert>
        <b-alert
          v-model="flagAlertEdit"
          variant="info"
          dismissible
        >¡Los datos han sido actualizados exitosamente!</b-alert>
        <b-alert v-model="flagAlertProfile" variant="warning" dismissible>
          ¡Aún no cuentas con perfil para crear mascotas! Ir a
          <b-link @click="goToProfile()">Crear perfil</b-link>
        </b-alert>
      </b-col>
    </b-row>

    <section>
      <b-row>
        <b-col lg="10" offset-lg="1">        
          <CCard accent-color="info">
            <CCardHeader>
              <strong>Mis mascotas</strong>    
              <div class="card-header-actions">                             
                <b-button 
                  class="s-bg s-bg-text mr-1"
                  size="md"
                  style="width:100%;"
                  @click="create()"
                  v-if="hasUserProfile">
                  <strong>Crear mascota</strong>                   
                </b-button>  
              </div>                                                                                                         
            </CCardHeader>
            <CCardBody> 
              <!-- Main table element -->

              <div v-for="pet in items" v-if="items.length > 0">
                <b-row>
                  <b-col lg="3" md="4" style="text-align: center">
                    <img 
                      :src="pet.url ? pet.url : defaultUserImg" 
                      alt=""
                      class="profile-user-image">
                  </b-col>
                  <b-col>                                       
                    <div>
                      <strong>{{pet.name}}</strong> 
                    </div>
                    <div>
                      Raza: {{pet.breed}}
                    </div>
                    <div>
                      Fecha nacimiento: {{pet.dof}}
                    </div>
                    <div>
                      Observaciones : {{pet.obs}}
                    </div>
                    <div>
                      Placa de identidad QR:
                      <CBadge 
                        shape="pill" 
                        color="success"
                        v-if="pet.tag == 'Si'">
                        {{pet.tag}}
                      </CBadge>
                      <CBadge 
                        shape="pill" 
                        color="warning"
                        v-else>
                        {{pet.tag}}
                      </CBadge>
                    </div>
                    <br>
                    <b-row>
                      <b-col 
                        lg="6"
                        style="margin-bottom:0.5rem;">
                        <b-button 
                          @click="medicInfoModal(pet)" 
                          size="md"
                          class="btn-overlay">
                          Ver datos médicos
                        </b-button>
                      </b-col> 
                      <b-col 
                        lg="6">
                        <b-button 
                          @click="info(pet.id)" 
                          size="md"
                          class="btn-overlay btn-warning">
                          Editar
                        </b-button>
                      </b-col>
                                                           
                    </b-row>
                  </b-col>
                </b-row>
                <hr>
              </div>
           
            </CCardBody> 
          </CCard>  
        </b-col>
      </b-row>   
    </section>
   

    <!-- Pet Medical info Modal -->
    <b-modal 
      title="Datos médicos" 
      v-model="flagPetMedicalInfoModal"       
      no-close-on-backdrop
      hide-header-close
      v-if="petSelected != null"
      >
      <p> 
        <strong>Mascota:</strong> {{petSelected.name}}
      </p>
      <p>
        <strong>Veterinaria:</strong> {{petSelected.vet}}
      </p>
      <p>
        <strong>Dirección veterinaria:</strong> {{petSelected.vetAddress}}
      </p>
      <p>
        <strong>Teléfono veterinaria:</strong> {{petSelected.vetPhone}}
      </p>
      
                
      <template #modal-footer>
        <div class="w-100">
          <b-row>
              <b-col lg="12">
                <b-button                  
                  size="md"                   
                  class="float-right"
                  @click="flagPetMedicalInfoModal=false">
                    Cerrar
                </b-button>
              </b-col>             
          </b-row>                             
        </div>
      </template>
    </b-modal>



  </b-container>
</template>

<script>
import moment from "moment";
import CreatePet from "./Create";
import EditPet from "./Edit";
import FormPet from "./Form";
export default {
  name: "indexPets",
  components: {
    CreatePet,
    EditPet,
    FormPet
  },
  data() {
    return {
      items: [],          
      pets: [],
      breeds: [],
      flagAlertCreation: false,
      flagAlertEdit: false,
      userId: null,
      hasUserProfile: true,
      flagAlertProfile: false,
      defaultUserImg: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
      flagPetMedicalInfoModal: false,
      petSelected: null,
    };
  },
  created() {
    this.userId = this.$store.getters.userId;
    let userProfile = JSON.parse(this.$store.getters.userProfile);    

    if (this.$route.query) {
      if (this.$route.query.edited == "true") {
        this.getUserProfile();
        this.flagAlertEdit = true;
        this.$route.query.edited = false;
      }
      if (this.$route.query.created == "true") {
        this.getUserProfile();
        this.flagAlertCreation = true;
        this.$route.query.edited = false;
      }
    }
    if (userProfile) {
      // console.log('petsIndex-profile:', userProfile.pets);
      this.items = userProfile.pets;
      this.items.forEach(item => {
        item["breed"] = item.breed.label;
      });
      this.fetchBreeds();
    } else {
      console.log("there is no userProfile");
      this.hasUserProfile = false;
      this.flagAlertProfile = true;
    }
  },

  methods: {
    info(id) { 
      this.$router.replace("/pets/form/" + id);      
    },  
    fetchBreeds() {
      this.$store
        .dispatch("fetchBreeds")
        .then(breeds => {
          this.breeds = breeds;
        })
        .catch(error => {
          console.log(error);
        });
    },
    create() {
      this.$router.replace("/pets/form/0");
    },
    getUserProfile() {
      this.$store
        .dispatch("fetchUser", { userId: this.userId })
        .then(response => {
          let userData = response;
          if (userData) {
            this.$store.commit("defUserDbId", userData.id);
            this.$store.commit("defUserProfile", JSON.stringify(userData));
            this.items = userData.pets;
            this.items.forEach(item => {
              item["breed"] = item.breed.label;
            });
          }
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
        });
    },
    goToProfile() {
      this.$router.replace("/users/profile");
    },
    medicInfoModal(pet) {
      console.log(pet)
      this.petSelected = pet
      this.flagPetMedicalInfoModal = true;
    },   
  }
};
</script>

<style scoped>
.profile-user-image {
  border-radius: 10%;
  border: 2px solid white;
  width: 10rem;
  height: 10rem;
  /* width: 10vw;
    height: 10vw; */
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  background-color: white;
}

</style>